<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#68B9A2;"
      d="M369.777,2.918v45.219L192.999,150.245l-84.425-18.654c-24.579,0-29.805-59.619-29.805-84.184
	c0-12.282,4.974-23.397,13.025-31.449s19.182-13.041,31.464-13.041H369.777z"
    />
    <path
      style="fill:#74CBB4;"
      d="M417.915,48.137v107.395l-53.543-5.287l-235.277,5.287V85.815c0-20.801,16.878-37.678,37.678-37.678
	h203.005C369.777,48.137,417.915,48.137,417.915,48.137z"
    />
    <path
      style="fill:#82DCC7;"
      d="M78.769,47.407c0,24.564,19.911,44.49,44.49,44.49h5.834h240.683h48.136h21.88h26.256v102.698H78.769
	V61.266V47.407z"
    />
    <path
      style="fill:#F64B4A;"
      d="M512,173.438v312.451c0,12.807-10.386,23.193-23.193,23.193H44.49c-1.225,0-2.436-0.044-3.647-0.146
	c-10.823-0.876-20.567-5.645-27.818-12.894C4.974,487.99,0,476.875,0,464.593V105.755c0,0.248,0,0.481,0.015,0.73
	c0.364,23.004,18.204,41.777,40.829,43.615c1.211,0.102,2.422,0.146,3.647,0.146h444.316C501.614,150.245,512,160.632,512,173.438z"
    />
    <path
      style="fill:#E84242;"
      d="M40.843,106.485v402.452c-10.823-0.876-20.567-5.645-27.818-12.894C4.974,487.99,0,476.875,0,464.593
	V106.485H40.843z"
    />
    <path
      style="fill:#D83434;"
      d="M78.769,61.266v88.979H64.182H44.49c-24.579,0-44.49-19.926-44.49-44.49
	c0-12.282,4.974-23.397,13.025-31.449S32.208,61.266,44.49,61.266H78.769z"
    />
    <circle style="fill:#FED159;" cx="405.519" cy="322.367" r="49.597" />
    <path
      style="fill:#F6C454;"
      d="M384.269,322.368c0-22.463,14.938-41.429,35.421-47.529c-4.492-1.338-9.248-2.067-14.176-2.067
	c-27.39,0-49.596,22.205-49.596,49.596s22.205,49.596,49.596,49.596c4.927,0,9.682-0.728,14.176-2.067
	C399.208,363.796,384.269,344.832,384.269,322.368z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
